import { BuilderElement } from "@builder.io/react";

const defaultTitle: BuilderElement = {
  "@type": "@builder.io/sdk:Element",
  layerName: "Section Title",
  class: "sectionTitle",
  bindings: {
    "style.top":
      'var _virtual_index="".concat(75*$index,"px");return _virtual_index',
  },
  actions: {
    click:
      "state.currentStep=(typeof state.completedStep === 'number' && state.completedStep >= $index) ? $index : state.currentStep; state.section = $sectionTag;",
  },
  code: {
    bindings: {
      "style.top": "return `${$index * 75}px`;\n",
    },
    actions: {
      click:
        "state.currentStep = (typeof state.completedStep === 'number' && state.completedStep >= $index) ? $index : state.currentStep; state.section = $sectionTag;",
    },
  },
  responsiveStyles: {
    large: {
      display: "flex",
      flexDirection: "row",
      flexShrink: "0",
      marginBottom: "8px",
      borderRadius: "8px",
      padding: "1rem",
      alignItems: "center",
      cursor: "pointer",
      borderWidth: "2px",
      borderStyle: "solid",
      fontSize: "1.125rem",
      fontWeight: "700",
      fontFamily: '"serenity", sans-serif',
      position: "absolute",
      left: "0",
      top: "0",
      width: "350px",
    },
    medium: {
      position: "relative",
      left: "unset",
      top: "unset",
      width: "100%",
    },
  },
  children: [
    {
      "@type": "@builder.io/sdk:Element",
      class: "completeIcon fas fa-check",
      layerName: "Section Complete Icon",
    },
    {
      "@type": "@builder.io/sdk:Element",
      layerName: "Section Number",
      class: "sectionNumber",
      children: [
        {
          "@type": "@builder.io/sdk:Element",
          bindings: {
            "component.options.text":
              "var _virtual_index=$index+1;return _virtual_index",
          },
          code: {
            bindings: {
              "component.options.text": "return $index + 1;\n",
            },
          },
          component: {
            name: "Text",
            options: {
              text: "",
            },
          },
        },
      ],
    },
    {
      "@type": "@builder.io/sdk:Element",
      class: "defaultTitle",
      responsiveStyles: {
        large: {
          maxWidth: "250px",
          lineHeight: "16px",
        },
      },
      component: {
        name: "Text",
        options: {
          text: "<p>Tom I need a title please</p>",
        },
      },
    },
    {
      "@type": "@builder.io/sdk:Element",
      class: "completedTitle",
      responsiveStyles: {
        large: {
          maxWidth: "250px",
          lineHeight: "16px",
        },
      },
      component: {
        name: "Text",
        options: {
          text: "<p>This Step is completed, what should go in here?</p>",
        },
      },
    },
    {
      "@type": "@builder.io/sdk:Element",
      class: "editIcon fas fa-pencil",
      layerName: "Section Edit Icon",
    },
  ],
};

const defaultDetail: BuilderElement = {
  "@type": "@builder.io/sdk:Element",
  layerName: "Section Content",
  class: "contentWrapper override",
  responsiveStyles: {
    large: {
      position: "relative",
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
    },
  },
  children: [
    {
      "@type": "@builder.io/sdk:Element",
      layerName: "Content Title",
      responsiveStyles: {
        large: {
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          textAlign: "center",
        },
      },
      children: [
        {
          "@type": "@builder.io/sdk:Element",
          layerName: "Section Number",
          class: "sectionNumber",
          responsiveStyles: {
            large: {
              fontWeight: "700",
              fontFamily: '"serenity", sans-serif',
              fontSize: "1.5rem",
              width: "3rem",
              borderRadius: "50%",
              margin: "0 auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "3rem",
              backgroundColor: "rgba(244, 224, 77, 1)",
            },
          },
          children: [
            {
              "@type": "@builder.io/sdk:Element",
              bindings: {
                "component.options.text":
                  "var _virtual_index=$index+1;return _virtual_index",
              },
              code: {
                bindings: {
                  "component.options.text": "return $index + 1;\n",
                },
              },
              component: {
                name: "Text",
                options: {
                  text: "",
                },
              },
            },
          ],
        },
        {
          "@type": "@builder.io/sdk:Element",
          responsiveStyles: {
            large: {
              fontWeight: "700",
              fontFamily: '"serenity", sans-serif',
              fontSize: "30px",
              display: "flex",
              flexDirection: "column",
              margin: "1rem auto 0",
              lineHeight: "1.2",
            },
          },
          component: {
            name: "Text",
            options: {
              text: "<h2>Meow - Give me a Title<h2>",
            },
          },
        },
      ],
    },
    {
      "@type": "@builder.io/sdk:Element",
      layerName: "Content Block",
      responsiveStyles: {
        large: {
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          textAlign: "center",
        },
      },
      children: [
        {
          "@type": "@builder.io/sdk:Element",
          component: {
            name: "Text",
            options: {
              text: "<p>Meow - Give me some content</p>",
            },
          },
        },
      ],
    },
    {
      "@type": "@builder.io/sdk:Element",
      layerName: "CTA Button",
      actions: {
        click:
          "state.currentStep=$index+1, state.completedStep <= $index ? state.completedStep=$index : null; state.section = $nextSectionTag;",
      },
      code: {
        actions: {
          click:
            "state.currentStep = $index+1;state.completedStep <= $index ? state.completedStep=$index : null; state.section = $nextSectionTag;",
        },
      },
      responsiveStyles: {
        large: {
          display: "flex",
          flexDirection: "column",
          flexShrink: "0",
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      component: {
        name: "CtaButton",
        options: {
          text: "Continue",
          showArrow: true,
        },
      },
    },
  ],
};

const extras: BuilderElement = {
  "@type": "@builder.io/sdk:Element",
  layerName: "Extras",
  responsiveStyles: {
    large: {
      display: "flex",
      flexDirection: "column",
      flexShrink: "0",
      marginTop: "2rem",
    },
  },
  children: [
    {
      "@type": "@builder.io/sdk:Element",
      actions: {
        click: "window.scrollTo({ top: 0, behavior: 'smooth' })",
      },
      code: {
        actions: {
          click: "window.scrollTo({ top: 0, behavior: 'smooth' })",
        },
      },
      responsiveStyles: {
        large: {
          display: "flex",
          flexDirection: "column",
          flexShrink: "0",
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      component: {
        name: "CtaButton",
        options: {
          text: "Back to Top",
          showArrow: false,
        },
      },
    },
  ],
};

const defaultItem: BuilderElement = {
  "@type": "@builder.io/sdk:Element",
  layerName: "Section (step)",
  responsiveStyles: {
    large: {
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
    },
  },
  children: [defaultTitle, defaultDetail],
};

export const JtcConfig: any = {
  name: "Join the Club",
  canHaveChildren: true,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2F0d66acbdde6849c3acf886eb909a8fb6%2Fdef9f04a68184c9782c071909812ee38?width=24",
  defaultStyles: {
    padding: "3rem 2rem 1rem",
    position: "relative",
    minHeight: "400px",
  },

  inputs: [
    {
      name: "sections",
      type: "list",
      broadcast: true,
      subFields: [
        {
          name: "section",
          type: "uiBlocks",
          defaultValue: [defaultTitle],
        },
        {
          name: "sectionTag",
          type: "text",
          defaultValue: "Section",
        },
      ],
      defaultValue: [
        {
          section: [defaultItem],
          sectionTag: "sectionTag",
        },
        {
          section: [defaultItem],
          sectionTag: "sectionTag",
        },
      ],
    },
    {
      name: "progressBar",
      helperText: "Turn off to remove the progress bar",
      type: "boolean",
      defaultValue: true,
    },
    {
      name: "progressBarColor",
      type: "color",
      helperText: "Color of the progress bar",
      defaultValue: "#F4E04D",
      showIf: (options: Map<string, any>) => options.get("progressBar"),
    },
    {
      name: "progressBarBackground",
      type: "color",
      helperText: "Color of the progress bar background",
      defaultValue: "#F6F6FF",
      showIf: (options: Map<string, any>) => options.get("progressBar"),
    },
    {
      name: "progressBarHeight",
      type: "number",
      helperText: "Height of the progress bar in pixels",
      defaultValue: 16,
      showIf: (options: Map<string, any>) => options.get("progressBar"),
    },
    {
      name: "showExtrasAtStart",
      type: "boolean",
      helperText: "Show extra content at the Beginging",
      defaultValue: false,
    },
    {
      name: "extras",
      type: "uiBlocks",
      hideFromUI: true,
      defaultValue: [extras],
    },
  ],
};
