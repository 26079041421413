import { builder } from "@builder.io/react";
import { GetStaticPropsContext } from "next";
import { getRootSections } from "@services/builderIO/root.sections";

const HOST = process.env.NEXT_PUBLIC_BASE_PATH;

export const getBuilderStaticProps = async (
  modelName: string,
  context: GetStaticPropsContext,
  path?: string,
  models?: string[]
) => {
  const _path =
    path || `/${(context.params?.page as string[])?.join("/") || ""}`;
  // Don't target on url and device for better cache efficiency
  const targeting = { urlPath: "_", device: "_" } as any;
  const page = await builder
    .get(modelName, {
      userAttributes: {
        ...targeting,
        urlPath: _path,
        host: HOST,
        stagingOnly: HOST?.includes(".com") ? false : true,
      },
    })
    .promise();

  const _sections = await getRootSections(models || []);

  // If there is a Builder page for this URL, this will be an object, otherwise it'll be null
  return {
    props: {
      builderPage: page || null,
      sections: _sections,
    },
    revalidate: 5,
  };
};
