import { useContext, useState, useEffect, MouseEvent } from "react";
import { useCart } from "@shopify/hydrogen-react";
import { StoreContext } from "@context/store.context";
import QuantityInput from "@components/elements/quantity-input.component";
import styles from "@styles/components/product/quantity-selector.module.scss";
import { SellingPlan } from "@shopify/hydrogen-react/storefront-api-types";

export default function CartQuantitySelector({ product }: { product: any }) {
  // Context
  const { handleChangeCartQuantity } = useContext(StoreContext) || {};

  // State
  const [autoShipItem, setAutoShipItem] = useState<boolean>(false);
  const [itemQuantity, setItemQuantity] = useState<number>(1);
  const [itemData, setItemData] = useState<any>(undefined);
  const [ready, setReady] = useState<boolean>(true);

  const { status } = useCart();

  const updateCart = (newQuantity: number) => {
    if (newQuantity > 0 && ready) {
      setItemQuantity(newQuantity);
      handleChangeCartQuantity &&
        handleChangeCartQuantity(
          { ...itemData.product, pid: itemData.pid, vid: itemData.vid },
          itemData.sellingPlanID,
          newQuantity - itemData.quantity
        );
    }
  };

  const isMax = (newQuanity: number): boolean => {
    if (itemData.maxQuantity !== 0) {
      return itemData.maxQuantity && newQuanity >= itemData.maxQuantity;
    }
    return false;
  };

  const minOptionClick = (): void => {
    if (itemData.quantity > 1) {
      updateCart(itemData.quantity - 1);
    }
  };

  const plusOptionClick = (): void => {
    updateCart(
      isMax(itemData.quantity + 1)
        ? itemData.maxQuantity
        : itemData.quantity + 1
    );
  };

  const defaultSellingPlan = (item: any): SellingPlan | undefined => {
    if (item.sellingPlanGroups.edges.length == 0) return;

    const defaultSellingPlanId = item.meta_defaultSellingPlan?.value;

    if (!defaultSellingPlanId)
      return item.sellingPlanGroups?.edges
        ?.find((x: any) => x)
        ?.node.sellingPlans.edges.find((x: any) => x)?.node;

    return item.sellingPlanGroups.edges
      .flatMap((x: any) => x.node.sellingPlans.edges.map((x: any) => x.node))
      .find((x: any) => x.id == defaultSellingPlanId);
  };

  useEffect(() => {
    if (product.merchandise) {
      const _item = {
        lid: product.id,
        mid: product.merchandise.id,
        vid: product.merchandise.id.split("/").pop(),
        pid: product.merchandise.product.id,
        autoShip:
          product.sellingPlanAllocation &&
          product.sellingPlanAllocation.sellingPlan
            ? true
            : false,
        quantity: product.quantity,
        product: product.merchandise.product,
        sellingPlanID: product.sellingPlanAllocation?.sellingPlan?.id,
        sellingPlan: defaultSellingPlan(product.merchandise.product) || null,
        maxQuantity:
          product.merchandise.product.inventory?.availableQuantity || 100,
      };
      if (status !== "updating") {
        setItemData(_item);
        setItemQuantity(_item.quantity);
        setAutoShipItem(_item.autoShip);
        setReady(true);
      } else {
        setReady(false);
      }
    }
  }, [product]);

  // Updates Auto Ship Based on Toggle
  const handleAutoShipToggle = (event: MouseEvent) => {
    event.preventDefault();
    if (ready) {
      const plan = autoShipItem ? null : itemData.sellingPlan.id;
      handleChangeCartQuantity &&
        handleChangeCartQuantity(
          { ...itemData.product, pid: itemData.pid, vid: itemData.vid },
          plan,
          0
        );
      /*
      linesUpdate([
        {
          id: itemData.lid,
          merchandiseId: itemData.mid,
          quantity: itemData.quantity,
          sellingPlanId: plan,
        },
      ]);*/
    }
  };

  return (
    <>
      {itemData && (
        <div
          className={`${styles.quantitySelector} ${styles.cartQuantitySelector}`}
        >
          <div className={styles.qInputWrapper}>
            <QuantityInput
              currentQuantity={itemQuantity}
              maximumQuantity={100}
              minimumQuantity={1}
              handleMinusClick={minOptionClick}
              handlePlusClick={plusOptionClick}
            />
          </div>
          {itemData.sellingPlan && (
            <div className={`${styles.fSelectorCart} f-selector`}>
              <div className="toggleWrapper">
                <label
                  htmlFor={`f-toggle-${itemData.pid}`}
                  onClick={(e) => handleAutoShipToggle(e)}
                >
                  <p className={styles.authoShipP}>Auto-Ship</p>
                  <input
                    type="checkbox"
                    name={`f-toggle-${itemData.pid}`}
                    id={`f-toggle-${itemData.pid}`}
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                    checked={autoShipItem}
                  />
                  <span></span>
                </label>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
