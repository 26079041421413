import { parse } from "url";
import Link from "next/link";

export function renderLink(props: any) {
  if (typeof props.href !== "string") {
    return <a {...props} />;
  }

  const parsed = parse(props.href);

  if (
    (parsed.hostname &&
      !(
        parsed.hostname.includes("builder.io") ||
        parsed.hostname.includes("localhost")
      )) ||
    parsed.hostname?.includes("forum.builder.io")
  ) {
    return <a {...props} />;
  }
  const relativePath = parsed.pathname || "";
  const useNextLink = relativePath === "/";
  const { children, ...rest } = props;

  if (useNextLink) {
    return <Link {...rest}>{children}</Link>;
  }

  return <a {...props} />;
}

export const RenderLink = renderLink;
