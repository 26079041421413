import { builder, Builder, withChildren } from "@builder.io/react";
import "@builder.io/widgets/dist/lib/builder-widgets-async";
import dynamic from "next/dynamic";
import { AccordionComponent } from "./cms_components/Accordion";
import { accordionConfig } from "./cms_components/Accordion.config";
import { JtcComponent } from "./cms_components/JTC";
import { JtcConfig } from "./cms_components/JTC.config";
import { FaqComponent } from "./cms_components/Faqs";
import { faqsConfig } from "./cms_components/Faqs.config";

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);
Builder.registerComponent(AccordionComponent, accordionConfig);
Builder.registerComponent(JtcComponent, JtcConfig);
Builder.registerComponent(FaqComponent, faqsConfig);

// Sunsetting
Builder.registerComponent(
  dynamic(() => import("./cms_components/hero.component")),
  {
    name: "Mini Hero",
    inputs: [
      {
        name: "headline",
        type: "string",
        required: true,
        defaultValue: "Headline",
      },
      {
        name: "subline",
        type: "string",
        required: false,
        defaultValue: "Sub Headline",
      },
      {
        name: "desktopImg",
        type: "file",
        required: true,
        friendlyName: "image",
        defaultValue: "/images/about/environmental/impact-header-image.webp",
        allowedFileTypes: ["png", "jpg", "webp"],
      },
      {
        name: "mobileImg",
        type: "file",
        required: true,
        friendlyName: "image",
        defaultValue:
          "/images/about/environmental/impact-header-image-mobile.webp",
        allowedFileTypes: ["png", "jpg", "webp"],
      },
      {
        name: "tabletImgPosition",
        helperText: "Position of the image on tablet, top, center or bottom",
        type: "string",
        required: true,
        defaultValue: "center",
      },
      {
        name: "isTextTop",
        type: "boolean",
        required: false,
        defaultValue: false,
      },
      {
        name: "cta",
        friendlyName: "CTA link",
        type: "list",
        required: false,
        subFields: [
          {
            name: "text",
            friendlyName: "text",
            type: "string",
          },
          {
            name: "link",
            friendlyName: "CTA link",
            type: "string",
          },
        ],
      },
      {
        name: "cta2",
        friendlyName: "CTA2 link",
        type: "list",
        required: false,
        subFields: [
          {
            name: "text",
            friendlyName: "text",
            type: "string",
          },
          {
            name: "link",
            friendlyName: "CTA link",
            type: "string",
          },
        ],
      },
      {
        name: "heroStylesDesktop",
        type: "json",
        required: false,
        defaultValue: {},
      },
      {
        name: "heroStylesMobile",
        type: "json",
        required: false,
        defaultValue: {},
      },
      {
        name: "textWrapperStyles",
        type: "json",
        required: false,
        defaultValue: {},
      },
      {
        name: "headlineStyles",
        type: "json",
        required: false,
        defaultValue: {},
      },
      {
        name: "sublineStyles",
        type: "json",
        required: false,
        defaultValue: {},
      },
    ],
  }
);

Builder.registerComponent(
  withChildren(dynamic(() => import("./cms_components/article.element"))),
  {
    name: "ArticleElement",
    inputs: [
      {
        name: "className",
        type: "string",
      },
      {
        name: "showLoading",
        type: "boolean",
      },
    ],
  }
);

Builder.registerComponent(
  withChildren(dynamic(() => import("./cms_components/section.element"))),
  {
    name: "SectionElement",
    inputs: [
      {
        name: "className",
        type: "string",
      },
      {
        name: "wrapper",
        type: "boolean",
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./cms_components/testimonial-collection")),
  {
    name: "TestimonialCollection",
    inputs: [
      {
        name: "headline",
        type: "string",
        required: true,
      },
      {
        name: "ctaText",
        friendlyName: "CTA Link Text",
        type: "string",
        required: true,
      },
      {
        name: "ctaLink",
        friendlyName: "CTA Link Url",
        type: "string",
        defaultValue: "/join-the-club",
        helperText: "/join-the-club",
      },
      {
        name: "reviews",
        type: "list",
        subFields: [
          {
            name: "title",
            type: "string",
            required: true,
          },
          {
            name: "review",
            type: "string",
            required: true,
          },
          {
            name: "name",
            type: "string",
            required: true,
          },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./cms_components/newsletterForm")),
  {
    name: "NewsLetterForm",
  }
);

Builder.registerComponent(
  dynamic(() => import("./cms_components/help-me-choose-section.component")),
  {
    name: "HelpMeChooseSection",
    inputs: [
      {
        name: "settings",
        type: "object",
        required: true,
        subFields: [
          {
            name: "title",
            type: "string",
            defaultValue: "title",
            required: true,
          },
          {
            name: "description",
            type: "string",
            defaultValue: "description",
            required: true,
          },
          {
            name: "litter_types",
            type: "list",
            subFields: [
              {
                name: "name",
                type: "string",
                defaultValue: "name",
                required: true,
              },
              {
                name: "class",
                type: "string",
                defaultValue: "class",
              },
              {
                name: "choose_litter",
                type: "list",
                subFields: [
                  {
                    name: "text",
                    type: "string",
                    defaultValue: "string",
                    required: true,
                  },
                ],
              },
            ],
          },
          {
            name: "specs",
            type: "list",
            subFields: [
              {
                name: "text",
                type: "string",
                defaultValue: "string",
                required: true,
              },
            ],
          },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./cms_components/carousel.component")),
  {
    name: "CarouselGallery",
    inputs: [
      {
        name: "settings",
        type: "object",
        subFields: [
          {
            name: "arrows",
            type: "boolean",
            defaultValue: false,
            required: true,
          },
          {
            name: "dots",
            type: "boolean",
            defaultValue: false,
            required: true,
          },
          {
            name: "infinite",
            type: "boolean",
            defaultValue: true,
            required: true,
          },
          {
            name: "autoplay",
            type: "boolean",
            defaultValue: true,
            required: true,
          },
          {
            name: "speed",
            type: "number",
            defaultValue: 500,
            required: true,
          },
          {
            name: "autoplaySpeed",
            type: "number",
            defaultValue: 5000,
            required: true,
          },
          {
            name: "cssEase",
            type: "string",
            defaultValue: "linear",
            required: true,
          },
          {
            name: "variableWidth",
            type: "boolean",
          },
          {
            name: "width",
            type: "number",
          },
          {
            name: "height",
            type: "number",
            defaultValue: 300,
            required: true,
          },
          {
            name: "instagram",
            type: "boolean",
          },
        ],
        required: true,
      },
      {
        name: "icon",
        type: "file",
        allowedFileTypes: ["png", "jpg", "webp", "svg"],
      },
      {
        name: "gallery",
        type: "list",
        required: true,
        subFields: [
          {
            name: "image",
            type: "file",
            allowedFileTypes: ["png", "jpg", "webp"],
          },
          {
            name: "alt",
            type: "string",
          },
        ],
      },
    ],
  }
);

// Product Card - sunsetting after PDP revamp
Builder.registerComponent(
  dynamic(() => import("./cms_components/pdp/pdp")),
  {
    name: "PdpProductDestop",
  }
);

Builder.registerComponent(
  dynamic(() => import("./cms_components/pdp/pdp.compare-similar-items")),
  {
    name: "PdpCompareSimilarItems",
  }
);

// CUSTOM BUTTON
Builder.registerComponent(
  dynamic(() => import("./cms_components/cta-button.component")),
  {
    name: "CtaButton",
    inputs: [
      {
        name: "text",
        type: "string",
        required: true,
      },
      {
        name: "link",
        type: "string",
      },
      {
        name: "BGcolor",
        type: "color",
        helperText: "Background color",
      },
      {
        name: "hoverColor",
        type: "color",
        helperText: "Hover color",
      },
      {
        name: "textColor",
        type: "color",
        helperText: "Text color",
      },
      {
        name: "isInverse",
        type: "boolean",
        helperText: "Invert colors",
      },
      {
        name: "showArrow",
        type: "boolean",
        helperText: "Show arrow",
      },
      {
        name: "autoShip",
        type: "boolean",
        helperText: "Show AutoShip Icon",
      },
      {
        name: "className",
        type: "string",
      },
      {
        name: "overrideMargin",
        type: "boolean",
        helperText: "Override margin",
      },
    ],
  }
);

// Inifinite Scroll
Builder.registerComponent(
  withChildren(dynamic(() => import("./cms_components/infinite-scroll"))),
  {
    name: "InfiniteScrollComponent",
    inputs: [
      {
        name: "handle",
        type: "string",
        required: true,
      },
      {
        name: "total",
        type: "number",
        required: true,
      },
      {
        name: "length",
        type: "number",
        required: true,
      },
      {
        name: "cursor",
        type: "string",
      },
      {
        name: "hasNextPage",
        type: "boolean",
      },
    ],
  }
);

// Select Box
Builder.registerComponent(
  dynamic(() => import("./cms_components/form-elements/selectBox")),
  {
    name: "SelectBox",
    inputs: [
      {
        name: "name",
        type: "string",
        required: true,
      },
      {
        name: "initial",
        type: "string",
      },
      {
        name: "options",
        type: "list",
        subFields: [
          {
            name: "name",
            type: "string",
            required: true,
          },
          {
            name: "value",
            type: "string",
            required: true,
          },
        ],
      },
    ],
  }
);

// Quantity Input
Builder.registerComponent(
  dynamic(
    () => import("./cms_components/form-elements/quantity-input.component")
  ),
  {
    name: "QuantityInput",
    inputs: [
      {
        name: "id",
        type: "string",
        required: true,
      },
      {
        name: "initialValue",
        type: "number",
      },
      {
        name: "maxQuantity",
        type: "number",
      },
      {
        name: "minQuantity",
        type: "number",
      },
    ],
  }
);

// Toggle
Builder.registerComponent(
  dynamic(() => import("./cms_components/form-elements/toggle.component")),
  {
    name: "Toggle",
    inputs: [
      {
        name: "id",
        type: "string",
        required: true,
      },
      {
        name: "label",
        type: "string",
        required: true,
      },
      {
        name: "checked",
        type: "boolean",
      },
      {
        name: "flexDirection",
        type: "boolean",
      },
    ],
  }
);
