import { builder } from "@builder.io/react";
import { getRootSections } from "@services/builderIO/root.sections";
import { getCollectionProductsByHandle } from "@services/shopify/collections";
import {
  getProductByHandle,
  getProductRecommendations,
} from "@services/shopify/products";
import type { Product, ShopifyAnalyticsProduct } from "@src/types/product.type";

const HOST = process.env.NEXT_PUBLIC_BASE_PATH;

async function getLayoutProps() {
  const _sections = await getRootSections([]);
  return {
    sections: _sections,
  };
}

function setProductMeta(shopifyData: any) {
  if (!shopifyData) {
    return null;
  }
  const analytics: ShopifyAnalyticsProduct = {
    productGid: shopifyData.id,
    name: shopifyData.title,
    brand: shopifyData.vendor,
    category: shopifyData.productType,
    price: shopifyData.variants[0].price,
    sku: shopifyData.variants[0].sku,
  };
  return analytics;
}

export async function resolveShopifyContent(
  modelName: string,
  type: string,
  path: string,
  targetingAttributes: Record<string, any>
) {
  let shopifyData: Product | any = {};

  const content = await builder
    .get(modelName, {
      userAttributes: {
        ...targetingAttributes,
        urlPath: path,
        host: HOST,
        stagingOny: HOST?.includes(".com") ? false : true,
      },
    })
    .toPromise();

  if (type === "collection") {
    try {
      shopifyData = await getCollectionProductsByHandle({
        handle: targetingAttributes.collectionHandle,
        to: 12,
      });
    } catch (error) {
      console.error(error);
    }
  }

  if (type === "product") {
    try {
      shopifyData = await getProductByHandle(targetingAttributes.handle, true);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    revalidate: 240,
    props: {
      page: content || null,
      shopifyData: shopifyData,
      type: type,
      ...(type === "product"
        ? { productAnalytics: setProductMeta(shopifyData) }
        : { collectionHandle: targetingAttributes.collectionHandle }),
      ...(await getLayoutProps()),
    },
  };
}
