import {
  BuilderAsyncRequestsContext,
  BuilderBlockComponent,
  BuilderElement,
  BuilderStoreContext,
  Builder,
} from "@builder.io/react";
import * as React from "react";
interface AccordionProps {
  items: {
    lineItem: BuilderElement[];
  }[];
  filter: string;
  data: any;
}

// TODO: change to slick grid
export class FaqComponent extends React.Component<AccordionProps> {
  divRef: HTMLElement | null = null;
  state = {
    open: [] as number[],
  };
  private _errors?: Error[];
  private _logs?: string[];

  componentDidMount() {
    setTimeout(() => {
      if (this.divRef) {
        this.divRef.dispatchEvent(
          new CustomEvent("builder:jtc:load", {
            bubbles: true,
            cancelable: false,
            detail: {
              ref: this,
            },
          })
        );
      }
    });
  }

  getAccordionItem(
    titleBlocks: BuilderElement[],
    detailBlocks: BuilderElement[],
    index: number,
    state: any
  ) {
    const open = this.state.open.indexOf(index) !== -1;

    return (
      // This will not work as expected with react 15
      // Did preact get the span replacmenet too?
      <React.Fragment key={index}>
        <div
          className={`faqItem ${open ? "active" : "closed"}`}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
          data-index={index}
          onClick={() => {
            if (open) {
              this.setState({
                ...this.state,
                open: [],
              });
            } else {
              this.setState({
                ...this.state,
                open: [index],
              });
            }
          }}
        >
          {titleBlocks.map((block, index) => (
            <BuilderBlockComponent
              key={index}
              block={{
                ...block,
                repeat: null,
              }}
              index={index}
              child={true}
            />
          ))}
        </div>
        {open && (
          <div className={`faqDetail ${open ? "active" : "closed"}`}>
            {detailBlocks.map((block, index) => (
              <BuilderBlockComponent
                key={index}
                block={{
                  ...block,
                  repeat: null,
                }}
                index={index}
                child={true}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <BuilderAsyncRequestsContext.Consumer>
        {(value) => {
          this._errors = value && value.errors;
          this._logs = value && value.logs;

          return (
            <BuilderStoreContext.Consumer>
              {(state) => (
                <div className="faq" style={{ position: "relative" }}>
                  {this.props.data &&
                    this.props.data.map((item: any, index: number) => {
                      const _filter = state.state[this.props.filter];
                      if (_filter && item.tags.includes(_filter)) {
                        const block = this.props.items[0]?.lineItem[0];
                        const childState = {
                          ...state.state,
                          $index: index,
                          $title: item.title,
                          $description: item.description,
                        };
                        return (
                          <BuilderStoreContext.Provider
                            key={index}
                            value={{ ...state, state: childState } as any}
                          >
                            {this.getAccordionItem(
                              block.children ? [block.children[0]] : [],
                              block.children ? [block.children[1]] : [],
                              index,
                              state.state
                            )}
                          </BuilderStoreContext.Provider>
                        );
                      }
                    })}
                </div>
              )}
            </BuilderStoreContext.Consumer>
          );
        }}
      </BuilderAsyncRequestsContext.Consumer>
    );
  }
}
