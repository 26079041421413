import { builder } from "@builder.io/react";

const rootSections = ["header", "footer"];

export const getRootSections = async (models: string[]) => {
  const modelsToFetch = models
    ? [...models, ...rootSections]
    : [...rootSections];

  const sections = modelsToFetch.map(async (model) => {
    const _model = await builder
      .get(model)
      .toPromise()
      .catch((err) => {
        console.log("Error fetching model", model, err);
        return {};
      });

    if (_model) {
      return { [model]: _model };
    }
    return {};
  });
  const _sections = await Promise.all(sections);

  return Object.assign({}, ..._sections);
};
