import { BuilderElement } from "@builder.io/react";

const defaultItem: BuilderElement = {
  "@type": "@builder.io/sdk:Element",
  layerName: "Line Item",
  responsiveStyles: {
    large: {
      borderStyle: "solid",
      borderWidth: "0 0 1px",
      borderColor: "rgba(74, 74, 74, 1)",
      display: "flex",
      flexDirection: "row",
      marginTop: "10px",
    },
  },
  children: [
    {
      "@type": "@builder.io/sdk:Element",
      class: "titleText",
      layerName: "Title",
      responsiveStyles: {
        large: {
          display: "flex",
          flexDirection: "row",
          borderWidth: "0 0 1px",
          borderStyle: "solid",
          borderColor: "rgba(155, 155, 155, 1)",
          marginTop: "10px",
          position: "relative",
        },
      },
      children: [
        {
          "@type": "@builder.io/sdk:Element",
          bindings: {
            "component.options.text":
              "var _virtual_index=$title;return _virtual_index",
          },
          code: {
            bindings: {
              "component.options.text": "$title",
            },
          },
          responsiveStyles: {
            large: {
              display: "flex",
              flexDirection: "column",
              position: "relative",
              flexShrink: "0",
              lineHeight: "2rem",
              height: "auto",
              fontSize: "16px",
              fontWeight: "700",
              textAlign: "left",
            },
            small: {
              maxWidth: "300px",
              lineHeight: "1.5rem",
            },
          },
          component: {
            name: "Text",
            options: {
              text: "I am a FAQ title. Click me!",
            },
          },
        },
      ],
    },
    {
      "@type": "@builder.io/sdk:Element",
      bindings: {
        "component.options.text":
          "var _virtual_index=$description;return _virtual_index",
      },
      code: {
        bindings: {
          "component.options.text": "$description",
        },
      },
      responsiveStyles: {
        large: {
          display: "flex",
          flexDirection: "column",
          position: "relative",
          flexShrink: "0",
          boxSizing: "border-box",
          marginTop: "10px",
          height: "auto",
          fontSize: "16px",
          textAlign: "left",
        },
      },
      component: {
        name: "Text",
        options: {
          text: "I am a FAQ ",
        },
      },
    },
  ],
};

export const faqsConfig: any = {
  name: "FAQS",
  canHaveChildren: true,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FagZ9n5CUKRfbL9t6CaJOyVSK4Es2%2Ffab6c1fd3fe542408cbdec078bca7f35",
  defaultStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  inputs: [
    {
      name: "items",
      type: "list",
      broadcast: true,
      subFields: [
        {
          name: "lineItem",
          type: "uiBlocks",
          defaultValue: [defaultItem],
        },
      ],
      defaultValue: [
        {
          lineItem: [defaultItem],
          title: "FAQ Title",
          description: "FAQ Description",
        },
      ],
    },
    {
      name: "data",
      type: "object",
      helperText: "Binder Data",
    },
    {
      name: "filter",
      type: "text",
      helperText: "State Filter",
    },
  ],
};
