import {
  BuilderAsyncRequestsContext,
  BuilderBlockComponent,
  BuilderBlocks,
  BuilderElement,
  BuilderStoreContext,
  stringToFunction,
  Builder,
} from "@builder.io/react";
import { stat } from "fs";
import * as React from "react";
interface AccordionProps {
  sections: {
    section: BuilderElement[];
    sectionTag: string;
  }[];
  progressBar: boolean;
  progressBarColor: string;
  progressBarBackground: string;
  progressBarHeight: number;
  showExtrasAtStart: boolean;
  extras: BuilderElement[];
}

// TODO: change to slick grid
export class JtcComponent extends React.Component<AccordionProps> {
  divRef: HTMLElement | null = null;
  state = {
    open: [0] as number[],
  };
  private _errors?: Error[];
  private _logs?: string[];

  componentDidMount() {
    setTimeout(() => {
      if (this.divRef) {
        this.divRef.dispatchEvent(
          new CustomEvent("builder:jtc:load", {
            bubbles: true,
            cancelable: false,
            detail: {
              ref: this,
            },
          })
        );
      }
    });
  }

  getAccordionItem(
    titleBlocks: BuilderElement[],
    detailBlocks: BuilderElement[],
    index: number,
    state: any
  ) {
    const isEditing = Builder.isEditing;

    const open = isEditing
      ? this.state.open.indexOf(index) !== -1
      : state.currentStep === index;
    const completed = state.completedStep >= index;

    return (
      <React.Fragment key={index}>
        <div
          className={`sectionTab ${
            open ? "active" : completed ? "completed" : "closed"
          }`}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
          data-index={index}
          onClick={() => {
            if (isEditing) {
              this.setState({
                ...this.state,
                open: [index],
              });
            }
          }}
        >
          {titleBlocks.map((block, index) => (
            <BuilderBlockComponent
              key={index}
              block={{
                ...block,
                repeat: null,
              }}
              index={index}
              child={true}
            />
          ))}
        </div>
        {open && (
          <div className={`section-detail ${open ? "active" : "closed"}`}>
            {detailBlocks.map((block, index) => (
              <BuilderBlockComponent
                key={index}
                block={{
                  ...block,
                  repeat: null,
                }}
                index={index}
                child={true}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }

  getProgressBar(state: any, props: AccordionProps) {
    const pbPosition =
      typeof state.completedStep === "number" && state.completedStep >= 0
        ? state.completedStep + 1
        : state.currentStep + 1;
    return (
      <div
        style={{
          width: `${(pbPosition / props.sections.length) * 100}%`,
          position: "relative",
          height: `${props.progressBarHeight}px`,
          backgroundColor: props.progressBarColor,
        }}
      />
    );
  }

  render() {
    const { progressBar, progressBarBackground, showExtrasAtStart } =
      this.props;
    const showStep = showExtrasAtStart ? 0 : 1;
    return (
      <BuilderAsyncRequestsContext.Consumer>
        {(value) => {
          this._errors = value && value.errors;
          this._logs = value && value.logs;

          return (
            <BuilderStoreContext.Consumer>
              {(state) => (
                <React.Fragment>
                  {progressBar && (
                    <div
                      className="progress-bar"
                      style={{
                        backgroundColor: progressBarBackground,
                        position: "absolute",
                        width: "100%",
                        top: "0",
                        left: "0",
                      }}
                    >
                      {this.getProgressBar(state.state, this.props)}
                    </div>
                  )}
                  <div className="jtc" style={{ position: "relative" }}>
                    {this.props.sections &&
                      this.props.sections.map((item, index) => {
                        const block = item.section[0];
                        const childState = {
                          ...state.state,
                          $index: index,
                          $sectionTag: item.sectionTag,
                          $nextSectionTag:
                            this.props.sections[index + 1]?.sectionTag,
                          $open: this.state.open.indexOf(index) !== -1,
                          $completed: state.state.completedStep >= index,
                        };
                        return (
                          <BuilderStoreContext.Provider
                            key={index}
                            value={{ ...state, state: childState } as any}
                          >
                            {this.getAccordionItem(
                              block.children ? [block.children[0]] : [],
                              block.children ? [block.children[1]] : [],
                              index,
                              state.state
                            )}
                          </BuilderStoreContext.Provider>
                        );
                      })}
                  </div>
                  {state.state.currentStep !== this.props.sections.length - 1 &&
                    state.state.currentStep >= showStep && (
                      <BuilderBlocks blocks={this.props.extras} />
                    )}
                </React.Fragment>
              )}
            </BuilderStoreContext.Consumer>
          );
        }}
      </BuilderAsyncRequestsContext.Consumer>
    );
  }
}
